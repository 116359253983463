import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SectionWrap from "../../../components/sectionWrap"
import Introtext from "../../../components/introtext"
import Headline from "../../../components/headline"
import * as ffsStyles from "../../../components/sectionWrap/sectionWrap.module.css"
import ContentmoduleAbs from "../../../components/contentmoduleAbs"
import {AnchorLink} from "gatsby-plugin-anchor-links"
// Background-Images
import firstBackgroundImage from "../../../images/welcomeipback-sw.jpg"
import secondBackgroundImage from "../../../images/3dliveviewback.svg"
import thirdBackgroundImage from "../../../images/dabplusback-sw.jpg"
import dftBackgroundImage from "../../../images/projekte/abb-digital-factory-tours/landing/dft-kat-back.jpg"
// Objeckt-Images
import firstObjectImage from "../../../images/projekte/abb-welcome-ip-simulator/landing/welcome-ip-simu.png"
import secondObjectImage from "../../../images/3dliveview.png"
import thirdObjectImage from "../../../images/dab01.png"
import dftObjectImage from "../../../images//projekte/abb-digital-factory-tours/dft.png"

const VirtuelleProduktpraesentation = function (props, ref) {
  var counter = 1
  const data = [
    {
      logo: "/logos/logo-abb.svg",
      titel: "ABB Digital Factory Tours",
      text: "Produktionsstandorte virtuell in 360° entdecken.",
      slug: "/projekte/virtuelle-produktpraesentationen/digital-factory-tours",
      objectImage: dftObjectImage,
      bgImage: dftBackgroundImage,
    },    
    {
      logo: "/logos/logo-abb.svg",
      titel: "Welcome IP Simulator",
      text: "Mit dem Welcome IP Simulator machen wir moderne Türkommunikationslösung interaktiv erlebbar.",
      slug: "/projekte/virtuelle-produktpraesentationen/welcome-ip-simulator",
      objectImage: firstObjectImage,
      bgImage: firstBackgroundImage,
    },
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "3D LiveView App",
      text: "Macht Produkte schon erlebbar, bevor sie installiert sind.",
      slug: "/projekte/virtuelle-produktpraesentationen/3d-liveview-app",
      objectImage: secondObjectImage,
      bgImage: secondBackgroundImage,
    },
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "Digitalradio DAB+ Simulator",
      text: "Hört sich gut an!",
      slug: "/projekte/virtuelle-produktpraesentationen/digitalradio-dab-plus-simulator",
      objectImage: thirdObjectImage,
      bgImage: thirdBackgroundImage,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Virtuelle Produktpraesentation"
        description="Virtuelle Produktsimulationen von Q:marketing"
      />

      <div className="page-container-offset">
        {/* 
          head
        */}
        <div className="container-fluid">
          <SectionWrap id="abc" bgCol={"#fff"} className="height50 my-5">
            <div className={`row ${ffsStyles.vcenterRowContent}`}>
              {/* left */}
              <div className={`col-12 col-md-8 px10`}>
                <Headline
                  titel="Virtuelle Produktpräsentation"
                  showCta={false}
                  skyhead={false}
                  text={<>Wir entwickeln Lösungen, mit denen Sie Ihre Produkte digital präsentieren können. So werden Ihre Kunden schon vor dem Kauf von Ihren Lösungen begeistert sein.<p className="pt-2"><AnchorLink to="/projekte/virtuelle-produktpraesentationen/#beispiele" title="↓ Beispiele" className="" /></p></>}
                />
              </div>
            </div>
          </SectionWrap>
        </div>

        <div id="beispiele"></div>        

        {data.map(e => {
          counter++
          var result = counter % 2 ? true : false

          return (
            <div className="container-fluid">
              <SectionWrap bgImg={e.bgImage} bgCol={"#fff"} className="height80 projektdetailkachel">
                <div className={`row ${ffsStyles.vcenterRowContent} bg-q-grey-mobile`}>
                  <div className={`col-12 mx-0 px-0`}>
                    {/* Vordergrund */}
                    <ContentmoduleAbs>
                      <div className={`row ${ffsStyles.vcenterRowContent}`}>
                        <div className={`col-md-6 mx-0 px-0 vorschaubild ${result ? "order-md-last" : ""}`}>
                          <img src={e.logo} alt="" className="d-md-none xyz logopic" />
                          <Link to={e.slug}><img src={e.objectImage} alt="" style={{ objectFit: "contain", pointerEvents: "visible" }} /></Link>
                        </div>
                        <div className={`col-md-6 mx-0 px-0 textpart ${result ? "order-md-first" : ""}`}>
                          <Introtext
                            skypic={e.logo}
                            skyhead={false}
                            titel={e.titel}
                            text={e.text}
                            linklabel="Mehr erfahren"
                            link={e.slug}
                          />
                        </div>
                      </div>
                    </ContentmoduleAbs>
                  </div>
                </div>
              </SectionWrap>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default VirtuelleProduktpraesentation